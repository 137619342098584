





































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import {
    individualEadKeys,
    eadValidateKeysListMap,
    hideEadKeys,
    getValueFromDropdown,
    eadDropdownValueMap, staffTabAccess
} from '../common';
import {AddAttrField, ListItem, ListItemPart, Overlay} from '../components';
import I18n from '../I18n';
import { Comp, Dict, Ead as EadNs, Edu, Org, Version } from '../types';
import StEad = Dict.StEad;
import {BIcon, BootstrapVueIcons} from 'bootstrap-vue'
import Datepicker from "vue2-datepicker";

Vue.use(BootstrapVueIcons)

// region Local types
type TDefaultValue = null | boolean | string | number;
// endregion

interface SettingEadPeriod {
    startPeriod: number | null;
    endPeriod: number | null;
}

// region Utils
const i18n = new I18n('modules.budget.staffing_table.config.*Ead*');

// noinspection SpellCheckingInspection
const allowedTypes: string[] = ['BOOL', 'DATE', 'NORMATIVE', 'NUMBER', 'SALARY_RATE', 'TEXT'];
// endregion


@Component({
    components: {
        ListItemPart,
        ListItem,
        'b-icon': BIcon,
        'add-attr-field': AddAttrField,
        'overlay': Overlay,
        'date-picker': Datepicker,
    }
})
export default class Ead extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: true,
    })
    public org!: Org;

    public get guCode(): string | null {
        const org = this.org;
        const type = org.type;
        switch (org.type) {
            case 'GU':
                return org.gu.code;
            case 'KGKP':
                return org.kgkp.codeGu ?? org.kgkp.codeGuOwner;
            default:
                return null;
        }
    }

    public get abpCode(): number | null {
        const guCode = this.guCode;
        if (guCode === null) return null;
        if (guCode.length < 3) return null;

        const abpCodeString = guCode.substring(0, 3);

        const result = parseInt(abpCodeString);
        if (String(result) !== abpCodeString) return null;

        return result;
    }

    @Prop({
        type: Object,
        required: true,
    })
    public version!: Version;

    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public readonly eduOrgType!: Dict.StEduOrgType | null;

    private get eduOrgTypeCode(): string | null {
        return (this.eduOrgType?.code ?? null);
    }

    @Prop({
        type: Array,
        required: true,
    })
    private budgetPrograms!: Array<Dict.EbkFunc>;

    private get budgetProgramOptions(): Array<Comp.DropdownItemDef<number | null>> {
        const locale = this.i18n.locale.trim().toLowerCase();

        const result = this
            .budgetPrograms
            .map((budgetProgram) => {
                let code = String(budgetProgram.prg);
                while (code.length < 3) {
                    code = '0' + code;
                }

                let name: string;
                if (locale === 'kk') {
                    name = budgetProgram.nameKk ?? '';
                } else {
                    name = budgetProgram.nameRu ?? '';
                }

                const result: Comp.DropdownItemDef<number> = {
                    value: budgetProgram.prg ?? -1,
                    text: `${code} - ${name}`,
                };
                return result;
            });
        return [
            {
                value: null,
                text: '',
            },
            ...result,
        ];
    }
    // endregion


    // region Утилиты
    private i18n = i18n;

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get loading() {
        return (
            this.eadLoading
            || this.eduHoursLoading
            || this.eduWlCLoading
        );
    }

    private get changed() {
        return (
            this.eadChanged
            || this.eduChanged
        );
    }

    private save() {
        this.eadSave();
        this.eduSave();
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('version', () => {
            this.reload();
        });
        this.$watch('eduOrgTypeCode', (eduOrgTypeCode: string | null) => {
            this.tryReloadEdu();
        });
        this.$watch('defaultValueMap', () => {
            this.defaultErrors.clear();
            for (const code of this.originalKeyMap.keys()) {
                if (eadValidateKeysListMap.has(code)) {
                    const valueError = eadValidateKeysListMap?.get(code);
                    const valueInput = this.defaultValueMap?.get(code);

                    if (valueError.min === null && typeof valueInput === 'number') {
                        if (valueInput > valueError.max) {
                            this.defaultErrors.set(code, this.$t(`modules.budget.staffing_table.config.*Ead*.error.${valueError.error}`) + valueError.max + '%');
                        }
                    }

                    if (valueError.max === null && typeof valueInput === 'number') {
                        if (valueInput < valueError.min) {
                            this.defaultErrors.set(code, this.$t(`modules.budget.staffing_table.config.*Ead*.error.${valueError.error}`) + valueError.min + '%');
                        }
                    }
                }
            }
        });

        this.$watch('defaultEadPeriod', () => {
            this.defaultEadPeriodErrors.clear()
            for (const code of this.defaultEadPeriod.keys()) {
                const settingPeriod = this.defaultEadPeriod.get(code)?? null
                const startPeriod = settingPeriod?.startPeriod ?? null
                const endPeriod = settingPeriod?.endPeriod ?? null
                if (startPeriod != null && endPeriod !== null) {
                    if (startPeriod > endPeriod) {
                        this.defaultEadPeriodErrors.set(code, this.$t(`modules.budget.staffing_table.config.*Ead*.error.start_period_over_end`) + '');
                    }
                }
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.loadEad(() => {
            this.reload();
        });
        this.tryReloadEdu();
    }
    // endregion


    // region Данные
    private eadLoading = false;

    private stEadList: Dict.StEad[] = []

    private allKeys: string[] = [];

    private keysStEadNormative: string[] =[];

    private groupedStEad(): { kind: string; stEadS: StEad[] }[] {
        const groups: { [key: string]: StEad[] } = {};

        this.stEadList.forEach(stEad => {
            const kind = stEad.kind;
            if (!groups[kind]) {
                groups[kind] = [];
            }
            if (hideEadKeys.notIncludes(stEad.code)) {
                groups[kind].push(stEad);
            }
        });

        return Object.keys(groups).map(kind => ({
            kind,
            stEadS: groups[kind]
        }));
    }

    private checkAllowedTypes = (type: string): boolean => {
        const index = allowedTypes.indexOf(type);
        return (index >= 0);
    };

    private initialKeyMap = (): Map<string, boolean> => {
        const result = new Map<string, boolean>();
        this.allKeys.forEach(code => {
            result.set(code, false);
        });
        return result;
    };

    private eadToMap: Map<string, Dict.StEad> = new Map<string, Dict.StEad>();

    private keyMap: Map<string, boolean> = new Map<string, boolean>();

    private originalKeyMap: Map<string, boolean> = new Map<string, boolean>();

    private defaultValueMap: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private defaultErrors: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private originalDefaultValueMap: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private originalDefaultValueMapForAllowRate: Map<string, boolean | null> = new Map<string, boolean | null>();

    private defaultValueMapForAllowRate: Map<string, boolean | null> = new Map<string, boolean | null>();

    private budgetProgramMap: Map<string, number> = new Map<string, number>();

    private originalBudgetProgramMap: Map<string, number> = new Map<string, number>();

    private attrAccountRateSet: Set<string> = new Set();

    private originalAttrAccountRateSet: Set<string> = new Set();

    private attrAccountFactLoadSet: Set<string> = new Set();

    private originalAttrAccountFactLoadSet: Set<string> = new Set();

    private defaultEadPeriod: Map<string, SettingEadPeriod> = new Map<string, SettingEadPeriod>();

    private originalDefaultEadPeriod: Map<string, SettingEadPeriod> = new Map<string, SettingEadPeriod>();

    private defaultEadPeriodErrors: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private get eadChanged(): boolean {
        if (this.keyMap.size !== this.originalKeyMap.size) {
            return true;
        }
        if (this.defaultValueMap.size !== this.originalDefaultValueMap.size) {
            return true;
        }
        if (this.budgetProgramMap.size !== this.originalBudgetProgramMap.size) {
            return true;
        }
        if (this.attrAccountRateSet.size !== this.originalAttrAccountRateSet.size) {
            return true;
        }
        if (this.attrAccountFactLoadSet.size !== this.originalAttrAccountFactLoadSet.size) {
            return true;
        }

        for (const code of this.allKeys) {
            if (this.keyMap.get(code) !== this.originalKeyMap.get(code)) {
                return true;
            }
        }

        for (const entry of this.defaultValueMap) {
            const code = entry[0];
            const value = entry[1];
            const originalValue = this.originalDefaultValueMap.get(code);
            if (value !== originalValue) {
                return true;
            }
        }

        for (const entry of this.defaultValueMapForAllowRate) {
            const code = entry[0];
            const value = entry[1];
            const originalValue = this.originalDefaultValueMapForAllowRate.get(code);
            if (value !== originalValue) {
                return true;
            }
        }

        for (const entry of this.budgetProgramMap) {
            const code = entry[0];
            const value = entry[1];
            const originalValue = this.originalBudgetProgramMap.get(code);
            if (value !== originalValue) {
                return true;
            }
        }

        for (const value of this.attrAccountRateSet) {
            if (!this.originalAttrAccountRateSet.has(value)) {
                return true;
            }
        }

        for (const value of this.attrAccountFactLoadSet) {
            if (!this.originalAttrAccountFactLoadSet.has(value)) {
                return true;
            }
        }

        for (const entry of this.defaultEadPeriod) {
            const code = entry[0];
            const value = entry[1];
            const originalValue = this.originalDefaultEadPeriod.get(code) ?? null;
            if (value.endPeriod !== originalValue?.endPeriod || value.startPeriod !== originalValue?.startPeriod) {
                return true;
            }
        }

        return false;
    }

    private getChecked(key: string): boolean {
        return (this.keyMap.get(key) === true);
    }

    private getCheckedAllowRate(key: string): boolean {
        return (this.defaultValueMapForAllowRate.get(key) === true);
    }

    private checkNormativeBdoMrp(normIndCode: null | string): boolean  {
        return  normIndCode === 'BDO' || normIndCode === 'MRP';
    }

    private toggleChecked(code: string) {
        const value = !this.keyMap.get(code);
        this.keyMap.set(code, value);
        this.keyMap = new Map(this.keyMap);

        if (this.keyMap.get(code) === false) { this.defaultValueMap.delete(code); }
        if (this.keyMap.get(code) === false) { this.defaultEadPeriod.delete(code); }

        if (this.keysStEadNormative.includes(code)) {
            this.defaultValueMapForAllowRate.has(code) ? this.defaultValueMapForAllowRate.delete(code) : this.defaultValueMapForAllowRate.set(code, value);
        }
    }

    // noinspection JSMethodCanBeStatic
    private getType(code: string): Dict.StEad.AddAttrType | undefined {
        const dictStEad: (Dict.StEad | undefined) = this.eadToMap.get(code);
        // noinspection JSIncompatibleTypesComparison
        if (dictStEad !== undefined) {
            return dictStEad.attrType;
        }
        return undefined;
    }

    // noinspection JSMethodCanBeStatic
    private getIDictStEad(code: string): Dict.StEad {
        return this.eadToMap.get(code)!!
    }

    private getAllowRate(code: string): boolean {
        return this.defaultValueMapForAllowRate.get(code) ?? true
    }

    // noinspection JSMethodCanBeStatic
    private getEadText(stEad: Dict.StEad): string  {
        if (this.isKazakh){
            String(stEad.nameKk)
        }
        return String(stEad.nameRu)
    }

    private get isKazakh(): boolean {
        return (this.$i18n.locale.trim().toLowerCase() === 'kk');
    }

    // noinspection JSMethodCanBeStatic
    private getOptions(code: string): Array<Comp.DropdownItemDef<null | boolean | number | string | Date>> | null {
        const isKazakh = (this.$i18n.locale.trim().toLowerCase() === 'kk');
        const eadDropdownList = eadDropdownValueMap.get(code) ?? null;
        if (!eadDropdownList) return null;

        const result = eadDropdownList.map(item => ({
            text: (isKazakh ? item.nameKk : item.nameRu),
            value: getValueFromDropdown(item, item.ead.attrType)
        }));

        result.unshift({value: null, text: ''});

        return result;
    }

    // noinspection JSMethodCanBeStatic
    private isInputFieldVisible(code: string): boolean {
        // http://192.168.0.146/issues/4537
        // Надо убрать окно "Пособие единовременное при увольнении ....."
        // noinspection SpellCheckingInspection
        if (code === 'BF_LSADSASPJRADETODMSC') return false;
        return (!individualEadKeys.includes(code));
    }

    private isEadNormative(code: string): boolean {
        return this.defaultValueMapForAllowRate.has(code);
    }

    private getDefaultValue(code: string): TDefaultValue | Date {
        const result = this.defaultValueMap.get(code) ?? null;
        if ((typeof result === 'number') && (this.getType(code) === 'DATE')) {
            return new Date(result);
        }
        return result;
    }

    private setDefaultValue(code: string, value: TDefaultValue | Date) {
        const defaultValueMap = new Map(this.defaultValueMap);
        if (value instanceof Date) {
            defaultValueMap.set(code, value.getTime());
        } else {
            defaultValueMap.set(code, value);
        }
        this.defaultValueMap = defaultValueMap;
    }

    private setNormValue(code: string, value: boolean) {
        const defaultValueMapForAllowRate = new Map(this.defaultValueMapForAllowRate);
        defaultValueMapForAllowRate.set(code, value)
        this.defaultValueMapForAllowRate = defaultValueMapForAllowRate
    }

    private getBudgetProgramCode(code: string): number | null {
        return (this.budgetProgramMap.get(code) ?? null);
    }

    private setBudgetProgramCode(code: string, budgetProgramCode: number | null) {
        if (budgetProgramCode === null) {
            if (this.budgetProgramMap.has(code)) {
                const map = new Map(this.budgetProgramMap);
                map.delete(code);
                this.budgetProgramMap = map;
            }
        } else {
            if (this.budgetProgramMap.get(code) !== budgetProgramCode) {
                const map = new Map(this.budgetProgramMap);
                map.set(code, budgetProgramCode);
                this.budgetProgramMap = map;
            }
        }
    }

    private getAttrAccountRate(code: string): boolean {
        return this.attrAccountRateSet.has(code);
    }

    private setAttrAccountRate(code: string, value: boolean) {
        const set = new Set(this.attrAccountRateSet);
        if (value) {
            set.add(code);
        } else {
            set.delete(code);
        }
        this.attrAccountRateSet = set;
    }

    private getAttrAccountFactLoad(code: string) {
        return this.attrAccountFactLoadSet.has(code);
    }

    private setAttrAccountFactLoad(code: string, value: boolean) {
        const set = new Set(this.attrAccountFactLoadSet);
        if (value) {
            set.add(code);
        } else {
            set.delete(code);
        }
        this.attrAccountFactLoadSet = set;
    }

    private createSettingEadPeriod(eadC: EadNs.EadC): SettingEadPeriod {
        return {
            startPeriod: eadC.startPeriod,
            endPeriod: eadC.endPeriod
        }
    }

    private getDefaultPeriod(code: string): SettingEadPeriod | null {
        return this.defaultEadPeriod.get(code) ?? null;
    }

    private getDefaultStartPeriod(code: string): Date | null {
        const startPeriod = this.defaultEadPeriod.get(code)?.startPeriod ?? null;
        if (startPeriod != null) {
            return new Date(startPeriod);
        } else {
            return null;
        }
    }

    private getDefaultEndPeriod(code: string): Date | null {
        const endPeriod = this.defaultEadPeriod.get(code)?.endPeriod ?? null;
        if (endPeriod != null) {
            return new Date(endPeriod);
        } else {
            return null;
        }
    }

    private setStartPeriod(code: string, value: Date | null) {
        const defaultValueMap = new Map(this.defaultEadPeriod);
        const settingEadPeriod = defaultValueMap.get(code) ?? null;

        if (settingEadPeriod) {
            settingEadPeriod.startPeriod = value?.getTime() ?? null
            defaultValueMap.set(code, settingEadPeriod)
            this.defaultEadPeriod = defaultValueMap
        } else {
            const item: SettingEadPeriod = {
                startPeriod: value?.getTime() ?? null,
                endPeriod: null
            }
            defaultValueMap.set(code, item)

        }
        this.defaultEadPeriod = defaultValueMap
    }

    private setEndPeriod(code: string, value: Date | null) {
        const defaultValueMap = new Map(this.defaultEadPeriod);
        const settingEadPeriod = defaultValueMap.get(code) ?? null;

        if (settingEadPeriod) {
            settingEadPeriod.endPeriod = value?.getTime() ?? null
            defaultValueMap.set(code, settingEadPeriod)
        } else {
            const item: SettingEadPeriod = {
                startPeriod: null,
                endPeriod: value?.getTime() ?? null
            }

            defaultValueMap.set(code, item)
        }
        this.defaultEadPeriod = defaultValueMap
    }

    private newMapOriginalDefaultEadPeriod() {
        const defaultEadPeriod: Map<string, SettingEadPeriod> = new Map();
        for (const [key, value] of this.defaultEadPeriod.entries()) {
            const copiedValue = JSON.parse(JSON.stringify(value));
            defaultEadPeriod.set(key, copiedValue);
        }
        this.originalDefaultEadPeriod = new Map(defaultEadPeriod);
    }

    private newMapDefaultEadPeriod() {
        const defaultEadPeriod: Map<string, SettingEadPeriod> = new Map();
        for (const [key, value] of this.originalDefaultEadPeriod.entries()) {
            const copiedValue = JSON.parse(JSON.stringify(value));
            defaultEadPeriod.set(key, copiedValue);
        }
        this.defaultEadPeriod = new Map(defaultEadPeriod);
    }


    private scrollToSection() {
        const section = document.getElementById('scroll');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    private loadEad(next?: () => void) {
        if (this.eadLoading) {
            console.error('Another loading is running, current loading cancelled');
            return;
        }

        Ax<Dict.StEad[]>(
            {
                url: `/api/budget/staffing_table/dict-st-ead/list`
            },
            data => {
                this.stEadList = data
                const result = new Map<string, Dict.StEad>();
                const eadNormative: string[] = [];
                data.forEach(value => {
                    result.set(value.code, value)

                    if (this.checkNormativeBdoMrp(value.dictNormIndCode)) {
                        eadNormative.push(value.code)
                    }
                });
                const allKeys: string[] = [];
                result.forEach((value, code) => {
                    allKeys.push(code);
                });

                this.keysStEadNormative = eadNormative
                this.allKeys = allKeys
                this.eadToMap = result;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.eadLoading = false;
                if (next !== undefined) next();
            }
        );
    }

    private reload() {
        if (this.eadLoading) {
            console.error('Another loading is running, current loading cancelled');
            return;
        }

        const versionId = this.version.id;
        if (versionId === null) {
            console.error('Version ID is null, cannot load config');
            return;
        }

        this.eadLoading = true;

        Ax<Array<EadNs.EadC>>(
            {
                url: `/api/budget/staffing_table/config/${versionId}/ead-c/list`
            },
            data => {
                const keyMap = this.initialKeyMap();
                const defaultValueMap: Map<string, TDefaultValue> = new Map();
                const defaultValueMapForAllowRate: Map<string, boolean | null> = new Map();
                const budgetProgramMap: Map<string, number> = new Map<string, number>();
                const attrAccountRateSet: Set<string> = new Set();
                const attrAccountFactLoadSet: Set<string> = new Set();
                const defaultEadPeriod: Map<string, SettingEadPeriod> = new Map();

                data.forEach(value => {
                    const code = value.ead!!.code;
                    const normIndCode = value.ead!!.dictNormIndCode;
                    const settingEadPeriod = this.createSettingEadPeriod(value)


                    value.allowRate = false;

                    keyMap.set(code, true)

                    defaultEadPeriod.set(code, settingEadPeriod)

                    if (this.checkNormativeBdoMrp(normIndCode)) {
                        defaultValueMapForAllowRate.set(code, value.allowRate)
                    }

                    let defaultValue: TDefaultValue = null;
                    switch (this.getType(code)) {
                        case 'BOOL':
                            defaultValue = value.valueBool;
                            break;
                        case 'DATE':
                            defaultValue = value.valueDate;
                            break;
                        case 'NORMATIVE':
                            defaultValue = value.valueNormRate;
                            break;
                        case 'NUMBER':
                            defaultValue = value.valueNumber;
                            break;
                        case 'SALARY_RATE':
                            defaultValue = value.valueSalaryRate;
                            break;
                        case 'TEXT':
                            defaultValue = value.valueText;
                            break;
                        default:
                            break;
                    }
                    defaultValueMap.set(code, defaultValue);

                    const budgetProgramCode = value.budgetProgram;
                    if ((budgetProgramCode !== null) && (value.ead?.allowBudgetProgSelect === true)) {
                        budgetProgramMap.set(code, budgetProgramCode);
                    }

                    const calcHint = value.calcHint;
                    if (calcHint !== null) {
                        calcHint.forEach((calcHintItem) => {
                            switch (calcHintItem) {
                                case 'TIME_RATE':
                                    attrAccountRateSet.add(code);
                                    break;
                                case 'FACT_WORKLOAD':
                                    if (value.ead?.allowFactWorkloadCalc === true) {
                                        attrAccountFactLoadSet.add(code);
                                    }
                                    break;
                            }
                        });
                    }

                    if (value.allowRate) {
                        attrAccountRateSet.add(code);
                    }
                });

                this.keyMap = keyMap;
                this.originalKeyMap = new Map(keyMap);
                this.defaultValueMap = defaultValueMap;
                this.originalDefaultValueMap = new Map(defaultValueMap);
                this.defaultValueMapForAllowRate = defaultValueMapForAllowRate
                this.originalDefaultValueMapForAllowRate = new Map(defaultValueMapForAllowRate);
                this.budgetProgramMap = budgetProgramMap;
                this.originalBudgetProgramMap = new Map(budgetProgramMap);
                this.attrAccountRateSet = attrAccountRateSet;
                this.originalAttrAccountRateSet = new Set(attrAccountRateSet);
                this.attrAccountFactLoadSet = attrAccountFactLoadSet;
                this.originalAttrAccountFactLoadSet = new Set(attrAccountFactLoadSet);
                this.defaultEadPeriod = defaultEadPeriod;
                this.newMapOriginalDefaultEadPeriod()

            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.eadLoading = false;
            }
        );
    }

    private reset() {
        this.keyMap = new Map(this.originalKeyMap);
        this.defaultValueMap = new Map(this.originalDefaultValueMap);
        this.defaultValueMapForAllowRate = new Map(this.originalDefaultValueMapForAllowRate);
        this.budgetProgramMap = new Map(this.originalBudgetProgramMap);
        this.attrAccountRateSet = new Set(this.originalAttrAccountRateSet);
        this.attrAccountFactLoadSet = new Set(this.originalAttrAccountFactLoadSet);
        this.newMapDefaultEadPeriod()
    }

    private eadSave() {
        if (this.eadLoading) {
            console.error('Cannot save data - another loading is running');
            return;
        }

        if (!this.eadChanged) {
            return;
        }

        const versionId = this.version.id;
        if (versionId === null) {
            console.error('Cannot save data - version ID is null');
            return;
        }

        const data: Array<EadNs.EadC> = [];
        this.keyMap.forEach((value, code) => {
            if (value) {
                const stEad = this.getIDictStEad(code);
                const budgetProgramCode = this.getBudgetProgramCode(code);
                const settingPeriod = this.getDefaultPeriod(code)

                let calcHint: Array<string> | null = null;
                if (this.getAttrAccountRate(code)) {
                    calcHint = ['TIME_RATE'];
                }
                if (this.getAttrAccountFactLoad(code)) {
                    if (calcHint === null) {
                        calcHint = ['FACT_WORKLOAD'];
                    } else {
                        calcHint.push('FACT_WORKLOAD');
                    }
                }

                const item: EadNs.EadC = {
                    id: null,
                    ead: stEad,
                    valueBool: null,
                    valueText: null,
                    valueNumber: null,
                    valueSalaryRate: null,
                    valueNormRate: null,
                    valueDate: null,
                    allowRate: false,
                    version: this.version,
                    budgetProgram: budgetProgramCode,
                    calcHint: calcHint,
                    startPeriod: settingPeriod?.startPeriod ?? null,
                    endPeriod: settingPeriod?.endPeriod ?? null
                };

                const type = this.getType(code);
                const defaultValue = this.getDefaultValue(code);
                if (defaultValue !== null) {
                    switch (type) {
                        case 'BOOL':
                            if (typeof defaultValue === 'boolean') {
                                item.valueBool = (defaultValue as boolean);
                            }
                            break;
                        case 'DATE':
                            if (typeof defaultValue === 'number') {
                                item.valueDate = (defaultValue as number);
                            }
                            break;
                        case 'NORMATIVE':
                            if (typeof defaultValue === 'number') {
                                item.valueNormRate = (defaultValue as number);
                            }
                            break;
                        case 'NUMBER':
                            if (typeof defaultValue === 'number') {
                                item.valueNumber = (defaultValue as number);
                            }
                            break;
                        case 'SALARY_RATE':
                            if (typeof defaultValue === 'number') {
                                item.valueSalaryRate = (defaultValue as number);
                            }
                            break;
                        case 'TEXT':
                            if (typeof defaultValue === 'string') {
                                item.valueText = (defaultValue as string);
                            }
                            break;
                        default:
                            break;
                    }
                }

                data.push(item);
            }
        });

        this.eadLoading = true;
        Ax(
            {
                url: `/api/budget/staffing_table/config/${versionId}/ead-c/list`,
                method: 'POST',
                data: data
            },
            () => {
                this.originalKeyMap = new Map(this.keyMap);
                this.originalDefaultValueMap = new Map(this.defaultValueMap);
                this.originalDefaultValueMapForAllowRate = new Map(this.defaultValueMapForAllowRate);
                this.originalBudgetProgramMap = new Map(this.budgetProgramMap);
                this.originalAttrAccountRateSet = new Set(this.attrAccountRateSet);
                this.originalAttrAccountFactLoadSet = new Set(this.attrAccountFactLoadSet);
                this.newMapOriginalDefaultEadPeriod()


                this.toast('success', this.i18n.translate('title'), this.i18n.translate('saved'));
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.eadLoading = false;
            }
        );
    }
    // endregion


    // region Образование - нормативные часы
    private eduHours: Array<Dict.StEduHours> = [];
    private eduHoursLoading = false;
    private eduWlC: Array<Edu.WlC> = [];
    private eduWlCOriginal: Array<Edu.WlC> = [];
    private eduWlCLoading = false;

    private get eduChanged(): boolean {
        if (this.eduWlC.length !== this.eduWlCOriginal.length) {
            return true;
        }

        const set = new Set<number>();
        this.eduWlC.forEach((eduWlC) => {
            const id = (eduWlC.hours?.id ?? null);
            if (id !== null) {
                set.add(id);
            }
        });
        this.eduWlCOriginal.forEach((eduWlC) => {
            const id = (eduWlC.hours?.id ?? null);
            if (id !== null) {
                set.delete(id);
            }
        });

        return (set.size > 0);
    }

    private tryReloadEdu() {
        if (this.eduOrgTypeCode === null) {
            this.eduHours = [];
            this.eduWlC = [];
        } else {
            this.reloadEduHours();
            this.reloadEduWlC();
        }
    }

    private reloadEduHours() {
        if (this.eduHoursLoading) {
            console.error('Cannot load EDU hours - another loading is running');
            return;
        }

        const eduOrgTypeCode = this.eduOrgTypeCode;
        if (eduOrgTypeCode === null) {
            console.error('Cannot load EDU hours - EDU org type code is null');
            return;
        }

        this.eduHoursLoading = true;
        // /api/budget/staffing_table/edu-sthours
        // ?code
        // List<DictStEduHoursDto>
        Ax<Array<Dict.StEduHours>>(
            {
                url: '/api/budget/staffing_table/edu-sthours',
                params: {
                    code: eduOrgTypeCode,
                },
            },
            data => {
                this.eduHours = data;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_edu_hours'), error.toString()),
            () => {
                this.eduHoursLoading = false;
            },
        );
    }

    private reloadEduWlC() {
        if (this.eduWlCLoading) {
            console.error('Cannot load EDU hours config - another loading is running');
            return;
        }

        this.eduWlCLoading = true;
        Ax<Array<Edu.WlC>>(
            {
                url: '/api/budget/staffing_table/edu-wl-c',
                params: {
                    idVersion: this.version.id,
                },
            },
            data => {
                this.eduWlC = data;
                this.eduWlCOriginal = [...data];
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_edu_hours_config'), error.toString()),
            () => {
                this.eduWlCLoading = false;
            },
        );
    }

    private getEduHoursTitle(item: Dict.StEduHours): string {
        const locale = this.i18n.locale.trim().toLowerCase();
        let name: string;
        let unit: string;
        switch (locale) {
            case 'en':
                name = item.nameEn;
                unit = item.unitEn;
                break;
            case 'kk':
                name = item.nameKk;
                unit = item.nameKk;
                break;
            default:
                name = item.nameRu;
                unit = item.unitRu;
                break;
        }

        return `${name} (${item.value} ${this.i18n.translate('edu_unit_hours')} ${unit})`;
    }

    private getEduWlCIndex(item: Dict.StEduHours): number | null {
        const result = this.eduWlC.findIndex((eduWlC) => (eduWlC.hours?.id === item.id));
        if (result >= 0) {
            return result;
        } else {
            return null;
        }
    }

    private isEduHoursSelected(item: Dict.StEduHours): boolean {
        const index = this.getEduWlCIndex(item);
        return (index !== null);
    }

    private onEduHoursChange(item: Dict.StEduHours) {
        setTimeout(() => {
            const eduWlC = [...this.eduWlC];

            const index = this.getEduWlCIndex(item);
            if (index === null) {
                eduWlC.push({
                    id: null,
                    version: null,
                    hours: item,
                });
            } else {
                eduWlC.splice(index, 1);
            }

            this.eduWlC = eduWlC;
        });
    }

    private eduSave() {
        if (this.eduWlCLoading) {
            console.error('Cannot save EDU workload config - another loading is running');
            return;
        }

        if (!this.eduChanged) {
            return;
        }

        const body = ((): Array<Dict.StEduHours> => {
            const idArray = this.eduWlC.map(eduWlC => (eduWlC.hours?.id ?? null));
            const idSet = new Set(idArray);
            return this.eduHours.filter((eduHours) => (idSet.has(eduHours.id)));
        })();

        const versionId = this.version.id;


        // /api/budget/staffing_table/edu-sthours
        // ?idVersion
        // body - List<DictStEduHoursDto>
        this.eduWlCLoading = true;
        Ax(
            {
                method: 'POST',
                url: '/api/budget/staffing_table/edu-wl-c',
                params: {
                    idVersion: versionId,
                },
                data: body,
            },
            () => {
                this.toast('success', '', this.i18n.translate('edu_saved'));
                this.eduWlCOriginal = [...this.eduWlC];
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_save_edu_hours_config'), error.toString()),
            () => {
                this.eduWlCLoading = false;
            },
        );
    }
    // endregion
}
